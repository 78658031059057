import { ReactNode } from 'react'

import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled'

interface PropTypes {
  children: ReactNode
  containerClass?: string
  e2eId?: string
}
interface ResponsivePropTypes extends PropTypes {
  className?: string
}
const Responsive = ({ children, className, containerClass, e2eId }: ResponsivePropTypes) => {
  const containerClasses = [className, containerClass]

  return (
    <ClassNames>
      {({ cx }) => (
        <div className={cx(containerClasses)} data-e2eid={e2eId || null}>
          {children}
        </div>
      )}
    </ClassNames>
  )
}

export const DesktopOnly = ({ children, containerClass, e2eId }: PropTypes) => {
  return (
    <StyledDesktopOnlyResponsive containerClass={containerClass} e2eId={e2eId || ''}>
      {children}
    </StyledDesktopOnlyResponsive>
  )
}

export const MobileOnly = ({ children, containerClass, e2eId }: PropTypes) => {
  return (
    <StyledMobileOnlyResponsive containerClass={containerClass} e2eId={e2eId || ''}>
      {children}
    </StyledMobileOnlyResponsive>
  )
}

const StyledMobileOnlyResponsive = styled(Responsive)`
  display: inherit;

  @media (min-width: 768px) {
    display: none !important;
  }
`

const StyledDesktopOnlyResponsive = styled(Responsive)`
  display: inherit;

  @media (max-width: 767px) {
    display: none !important;
  }
`
